import '../styles/index.scss';

// Landkit and Landkit Quill config
import '../../landkit/src/js/bootstrap';
import '../../landkit/src/js/quill';
import '../../landkit/src/js/flickity';

import Cookies from 'js-cookie';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Swiper from 'swiper/bundle';
import flatpickr from "flatpickr";

// Make cookies library available everywhere
window.Cookies = Cookies;

// Alpine
Alpine.plugin(collapse);
window.Alpine = Alpine;
Alpine.start();

window.Swiper = Swiper;
window.flatpickr = flatpickr;
